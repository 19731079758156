import React, { useEffect, useState } from "react";
import "./Header.css";

import { Link } from "react-router-dom";
import MobileNavDrawer from "./mobileNavDrawer/MobileNavDrawer";

import gemecosystemLogo from "../../assets/gemecosystemLogo.svg";
import mobileViewMenuDrawerIcon from "../../assets/mobileViewMenuDrawerIcon.svg";
import navBarCrossIcon from "../../assets/navBarCrossIcon.svg";
import { Button } from "primereact/button";
import { headerLinksData } from "../../helper/cardsData/homepageMockData";
import jewelIcon from "../../assets/jewelIcon.svg";
import CustomExternalLinkIcon from "../customExternalLinkIcon/CustomExternalLinkIcon";
import { useLocation } from "react-router-dom";
import AgileCrafterIcon from "../../assets/agileCrafterIcon.svg";
import JewelIcon from "../../assets/jewelIcon.svg";

const Header = () => {
  const [isMobileNavDrawerOpen, setIsMobileNavDrawerOpen] = useState(false);

  const location = useLocation();
  const [windowHash, setWindowHash] = useState("");
  useEffect(() => {
    setWindowHash(window.location.hash);
  }, [location]);

  const MenuOptionsHeading = ({
    headingIcon,
    heading,
    showExternalLinkIcon,
  }) => {
    return (
      <div className="flex  items-center  gap-x-1">
        {headingIcon && (
          <div className="h-7 w-7">
            <img src={headingIcon} alt={heading} />
          </div>
        )}
        <p className="header-link-menu-content-heading ">{heading}</p>
        {showExternalLinkIcon && <CustomExternalLinkIcon fillColor="#010101" />}
      </div>
    );
  };
  const MenuOptions = ({
    link,
    betaLink,
    openInNewTab,
    isBlueColor,
    name,
    showExternalLink,
  }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const fillColor = isBlueColor
      ? "#407ec4"
      : isHovered
      ? "#407ec4"
      : "#5b5b5b";
    return (
      <div
        key={name}
        className=" w-fit"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Link
          to={
            //either env is prod OR beta env Link does not exist
            process.env.REACT_APP_ENV === "prod" || !betaLink ? link : betaLink
          }
          target={openInNewTab ? "_blank" : "_self"}
          className=" mt-2 flex items-center "
        >
          <p
            className={`header-link-menu-content-link ${
              isBlueColor && "!text-curiousBlue"
            } ${isHovered && "!text-curiousBlue"}`}
          >
            {name}
          </p>
          {showExternalLink && <CustomExternalLinkIcon fillColor={fillColor} />}
        </Link>
      </div>
    );
  };

  const HeaderLinkMenu = ({ icon, heading, description, menuContent }) => {
    return (
      <div className="header-link-menu-wrapper flex items-start gap-x-4">
        <img loading="lazy" src={icon} alt={`icon of ${heading}`} />
        <div>
          <p className="header-link-menu-heading">{heading}</p>
          <div className="relative">
            <p className="header-link-menu-description">{description}</p>
          </div>
          <div className="mt-6 flex items-start justify-between gap-x-10">
            {menuContent.map((item) => {
              return (
                <div key={item.id}>
                  <div className="w-fit">
                    {item.headingLink || item.headingBetaLink ? (
                      <Link
                        to={
                          //either env is prod OR beta env Link does not exist
                          process.env.REACT_APP_ENV === "prod" ||
                          !item.headingBetaLink
                            ? item.headingLink
                            : item.headingBetaLink
                        }
                        target={item.showExternalLink ? "_blank" : "_self"}
                      >
                        <MenuOptionsHeading
                          headingIcon={item.headingIcon}
                          heading={item.heading}
                          showExternalLinkIcon={item.showExternalLink}
                        />
                      </Link>
                    ) : (
                      <MenuOptionsHeading
                        headingIcon={item.headingIcon}
                        heading={item.heading}
                      />
                    )}
                  </div>
                  <div className="mt-4">
                    {item.links.map((child, index) => {
                      return (
                        <MenuOptions
                          key={index}
                          link={child.link}
                          betaLink={child.betaLink}
                          openInNewTab={child.openInNewTab}
                          isBlueColor={child.isBlueColor}
                          name={child.name}
                          showExternalLink={child.showExternalLink}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <header className="header-shadow sticky-header">
      <div className=" mx-auto max-w-screen-2xl">
        <div className="flex h-14 items-center justify-between px-6 py-3 lg:h-16 lg:px-16 lg:py-0">
          {/* common for mobile and desktop */}
          <Link className="self-stretch lg:py-3" to="/">
            <div className="gemEcosystem-text flex h-full items-center ">
              <img
                className="self-stretch"
                src={gemecosystemLogo}
                alt="gemEcoSystem logo"
              />
              <p className="ml-1 text-curiousBlue">GEM</p>
              <p className="text-heading">ECOSYSTEM</p>
            </div>
          </Link>

          {/* visible on desktop only */}
          <div className="hidden h-full gap-8 text-heading lg:flex lg:items-center">
            {headerLinksData.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className="header-link-wrapper flex h-full items-center"
                >
                  {item.link ? (
                    <Link
                      to={item.link}
                      className={`header-link ${
                        windowHash === item.hash && "header-link-active"
                      }`}
                    >
                      {item.name}
                    </Link>
                  ) : (
                    <p className="header-link cursor-default">{item.name}</p>
                  )}

                  {item?.headerLinkMenu && (
                    <HeaderLinkMenu
                      icon={item.headerLinkMenu.icon}
                      heading={item.headerLinkMenu.heading}
                      description={item.headerLinkMenu.description}
                      menuContent={item.headerLinkMenu.menuChildren}
                    />
                  )}
                </div>
              );
            })}
          </div>

          {/* visible on desktop only */}
          <div className="hidden space-x-4 lg:flex lg:items-center h-full">
            <Link to={"/sign-up"}>
              <Button
                label="Sign up"
                title="Sign up with Jewel"
                className="base-button glow-button tertiary-button  "
                iconPos="left"
              />
            </Link>
            <div className="button-wrapper h-full py-3 flex item-center">
              <Button
                label="Login"
                title="Login with Jewel"
                className="base-button glow-button primary-button"
                iconPos="left"
              />

              <div className="button-options lg:px-2 lg:py-2">
                <Link
                  to={
                    process.env.REACT_APP_ENV === "prod"
                      ? "https://agilecrafter.gemecosystem.com/"
                      : "https://agilecrafter-beta.gemecosystem.com/"
                  }
                  target="_blank"
                >
                  <Button className="base-button custom-btn flex w-full gap-2">
                    <img
                      src={AgileCrafterIcon}
                      alt="Button icon"
                      className="p-button-icon "
                    />
                    <p className="text-base">Login with Agile Crafter</p>
                  </Button>
                </Link>
                <Link
                  to={
                    process.env.REACT_APP_ENV === "prod"
                      ? "https://jewel.gemecosystem.com/#/login"
                      : "https://jewel-beta.gemecosystem.com/#/login"
                  }
                  target="_blank"
                >
                  <Button className="base-button custom-btn flex w-full gap-2">
                    <img
                      src={JewelIcon}
                      alt="Button icon"
                      className="p-button-icon k h-10 w-40"
                    />
                    <p className="text-base">Login with Jewel</p>
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          {/* visible on mobile screen only */}
          <div className="lg:hidden">
            <img
              loading="lazy"
              onClick={() => {
                setIsMobileNavDrawerOpen(!isMobileNavDrawerOpen);
              }}
              src={
                isMobileNavDrawerOpen
                  ? navBarCrossIcon
                  : mobileViewMenuDrawerIcon
              }
              alt="drawerMenu"
            />
            <MobileNavDrawer
              open={isMobileNavDrawerOpen}
              setOpen={setIsMobileNavDrawerOpen}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
