import "./App.css";
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createHashRouter,
} from "react-router-dom";
import Homepage from "./pages/homePage/Homepage";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import ComingSoon from "./pages/comingSoon/ComingSoon";
import ErrorPage from "./pages/errorPage/ErrorPage";
import Header from "./common/header/Header";
import Footer from "./common/footer/Footer";
import GemAcademy from "./pages/gemAcademy/GemAcademy";
import AboutUs from "./pages/aboutUs/AboutUs";
import SignUp from "./pages/signUp/SignUp";
import SignUpMain from "./pages/signUpMain/SignUpMain";
import SignUpOTP from "./pages/SignUpOTP/SignUpOTP";
import LoginRouteHelper from "./pages/loginRoute/LoginRouteHelper";
import ChangeEmail from "./pages/changeEmail/ChangeEmail";
import GemConsultancy from "./pages/gemConsultacy/GemConsultancy";
import { ToastContainer } from "react-toastify";
import HowItWorks from "./pages/howItWorks/HowItWorks";
import ScrollToTop from "./common/scrollToTop/ScrollToTop";
import { useRoutes, useLocation } from 'react-router-dom';
import SignUpRevamp from "./pages/signUp/SignUpRevamp";


const router = createHashRouter([
  {
    element: <Root />,
    children: [
      { path: "/", element: <Homepage />, errorElement: <ErrorPage /> },
      {
        path: "/gem-academy",
        element: <GemAcademy />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/gem-consultancy",
        element: <GemConsultancy />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/how-it-works?",
        element: <HowItWorks />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/coming-soon",
        element: <ComingSoon />,
      },
      {
        path: "*",
        element: <ComingSoon />,
      },
      {
        path: "/sign-up",
        element: <SignUpRevamp />,
      },
      {
        path: "/account-verification",
        element: <SignUpOTP />
      },
      {
        path: "/change-email",
        element: <ChangeEmail /> 
      },
      {
        path: "/login-route",
        // element: <LoginRoute /> 
        element: <LoginRouteHelper /> 

      }  ,
      {
        path: "/sign-up-main",
        element: <SignUpMain /> 
      }  
    ],
  },
]);

function Root() {
  const location = useLocation();
  const hideHeaderFooter = location.pathname === "/sign-up"|| location.pathname === "/change-email"|| location.pathname === "/account-verification"|| location.pathname === "/login-route"|| location.pathname === "/sign-up-main";
  return (
    <PrimeReactProvider>
      <ScrollRestoration />
      <ToastContainer />
      {!hideHeaderFooter && <Header />}
      <ScrollToTop />
      <Outlet />
      {!hideHeaderFooter && <Footer />}
    </PrimeReactProvider>
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
